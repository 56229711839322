/* Wrapper cho toàn bộ phần content */
.content4-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  /* Đảm bảo ảnh không bị kéo giãn */
  background-position: center;
  /* Canh giữa ảnh */
  width: 100%;
  height: 130vh;
  /* Chiếm toàn bộ chiều cao của màn hình */
  position: relative;
  /* Đặt position relative để sử dụng position absolute cho các phần tử con */
}

/* Nội dung chính của phần content */
.content4-box {
  width: 100%;
  height: 100%;
  position: relative;
  /* Cho phép phần tử con sử dụng absolute */
}


/* Đoạn văn 1: Góc phần tư thứ nhất (Góc phải trên) */
.text-1 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  margin-top: 10%;
  margin-right: 8%;
  position: absolute;
  top: 0;
  /* Đặt phần tử ở góc trên */
  right: 0;
  /* Đặt phần tử ở góc phải */
  padding: 20px;
  color: black;
  border-radius: 8px;
  max-width: 400px;
  /* Đảm bảo không rộng quá */
}

.phone-image {
  position: absolute;
  right: 10px;
  /* Đặt ảnh vào góc phải */
  bottom: 0px;
  /* Đặt ảnh vào góc dưới */
  width: 300px;
  /* Kích thước ảnh */
  display: none;
  /* Ẩn ảnh mặc định */
}

/* Đoạn văn 2: Góc phần tư thứ ba (Góc trái dưới) */
.text-2 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5%;
  margin-left: 20%;
  position: absolute;
  bottom: 0;
  /* Đặt phần tử ở góc dưới */
  left: 0;
  /* Đặt phần tử ở góc trái */
  padding: 20px;
  color: black;
  border-radius: 8px;
  max-width: 400px;
  /* Đảm bảo không rộng quá */
}

/* Button Join Now */
.btn-join4 {
  padding: 10px 20px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 25px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 30px;
}

.btn-join4:hover {
  background-color: #0056b3;
}

/* Media queries để responsive */
@media (max-width: 768px) {

  /* Điều chỉnh vị trí và kích thước của text-1 và text-2 trên màn hình nhỏ */
  .text-1 {
    color: black;
    max-width: 250px;
    padding: 15px;
    margin-right: 3%;
  }

  .text-2 {
    max-width: 250px;
    padding: 15px;
    margin-left: 10%;
    /* Thêm background image khi màn hình nhỏ */
    /* background-image: url('src/assets/Person_CT4.png');  */
    background-size: cover;
    /* Đảm bảo ảnh bao phủ hết khu vực */
    background-position: center;
    /* Canh giữa ảnh */
    color: black;
    /* Đảm bảo chữ vẫn dễ nhìn */
  }

  /* Button Join Now sẽ nhỏ lại trên màn hình nhỏ */
  .btn-join4 {
    border-radius: 20px;
    font-size: 2em;
    /* Giảm kích thước chữ */
    padding: 8px 16px;
    /* Giảm padding */
    margin-left: 20px;
  }
}

/* Media queries cho màn hình nhỏ hơn 480px */
@media (max-width: 480px) {

  .content4-wrapper {
    height: 140vh;
    /* Đặt position relative để sử dụng position absolute cho các phần tử con */
  }

  .phone-image {
    display: block;
    /* Hiển thị ảnh */
  }

  /* Điều chỉnh vị trí và kích thước của text-1 và text-2 trên màn hình nhỏ hơn */
  .text-1 {
    margin-top: 40%;
    font-size: 1em;
    max-width: 250px;
    padding: 12px;
    margin-right: 2%;
  }

  .text-2 {
    font-size: 1em;
    max-width: 300px;
    padding: 12px;
    margin-left: 10%;
    margin-bottom: 40%;
    background-size: cover;
    /* Đảm bảo ảnh bao phủ hết khu vực */
    background-position: center;
    /* Canh giữa ảnh */
    color: black;
    /* Đảm bảo chữ vẫn dễ nhìn */
  }

  /* Button Join Now nhỏ hơn nữa */
  .btn-join4 {
    font-size: 30px;
    padding: 6px 12px;
    margin-left: 15px;
  }
}
