/* Đảm bảo background có màu xanh dương và content chiếm toàn màn hình */
.content2-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh; /* Chiếm toàn bộ chiều cao của cửa sổ */
    background-color: #FFFf; /* Màu xanh dương */
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Bố cục nội dung với thẻ h1 và p */
  .content2-box {
    text-align: center;
/* Nền trắng cho hộp chứa nội dung */
    border-radius: 10px;
    padding: 30px;
    max-width: 800px; /* Giới hạn chiều rộng */
    width: 100%;
    box-sizing: border-box;
  }
  
  .content2-box h1 {
    color: orange;
    font-size: 5rem;
    margin-bottom: 20px;
  }
  
  .content2-box p {
    font-weight: bold;
    font-size: 1.2rem;
    color: black; /* Màu chữ đen cho đoạn văn bản */
    line-height: 1.6;
  }
  