/* content-wrapper: Wrapper của phần nội dung */
.content-wrapper {
  background-size: cover;
  /* Đảm bảo ảnh nền không bị kéo giãn */
  background-position: center;
  /* Canh giữa ảnh */
  width: 100%;
  /* Chiếm toàn bộ chiều rộng */
  height: 70vh;
  /* Chiếm toàn bộ chiều cao của màn hình */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* content-box: Sắp xếp các cột */
.content-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Cho phép các phần tử chuyển sang dòng mới khi cần thiết */
  width: 100%;
  /* padding: 20px; */
  box-sizing: border-box;
}

/* Cột chứa các phần tử đếm */
.column-count {
  margin-top: 20px;
  flex: 1;
  min-width: 250px;
  /* Đảm bảo cột không quá nhỏ */
  margin-right: 20px;
  margin-left: 30%;
}

/* Cột chứa button và văn bản */
.column-text1 {
  font-weight: bold;
  padding-right: 10%;
  font-size: 1.2rem;
  color: black;
  flex: 1;
  min-width: 250px;
  /* Đảm bảo cột không quá nhỏ */
  padding-left: 30px;
  display: flex;
  justify-content: center;   /* Căn giữa button theo chiều ngang */
  align-items: center;       /* Căn giữa button theo chiều dọc */
  flex-direction: column;    /* Đảm bảo các phần tử con xếp theo cột */
  width: 100%;       
}

/* Button Join Now */
.btn-join1 {
  width: 50%;                /* Chiều rộng button */
  padding: 15px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: center;        /* Căn giữa văn bản trong button */
}
/* Counter phần tử */
.counter {
  /* margin-top: 50px; */
  font-size: 20px;
  color: #ffff;
  margin-bottom: 15px;
}

.counter h1 {
  color: orange;
}

.counter p {
  font-weight: bold;
  color: black;
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .content-box {
    flex-direction: column;
    /* Chuyển các cột thành hàng */
    align-items: center;
  }

  .column-text,
  .column-count {
    min-width: 100%;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }

  .btn-join1 {
    width: 60%;
    /* Đảm bảo button không chiếm toàn bộ chiều rộng */
    padding: 12px;
  }
}

@media (max-width: 480px) {

  .column-text1,
  .column-count {
    min-width: 70%;
    margin-right: 20;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }

  .content-wrapper {
    height: 80vh;
  }

  .container-text1 {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  /* Căn giữa button */
  .btn-join1 {
    
    font-size: 20px;
    padding-left: 0;
    padding-right: 0;

    /* Căn giữa nội dung trong button */
    display: flex;
    justify-content: center;
    /* Căn giữa button */
    align-items: center;
    /* Căn giữa chữ trong button */
    text-align: center;
    /* Căn giữa chữ */
    width: 50%;
    /* Đảm bảo button chiếm toàn bộ chiều rộng */
  }
}