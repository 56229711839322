.hero8 {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 300px;
}

.review {
  padding-left: 10%;
}

.review img {
  width: 90%;
  height: 800px;
  border-radius: 10px;
}
.text-review{
  margin-top: 100px;
  font-weight: bold;
  padding-right: 10%;
  font-size: 3rem;
}
/* Định dạng cho Slider */
.slider {
  width: 80%;
  margin: 0 auto;
  padding: 40px 0;
}

/* Responsive */
@media (max-width: 768px) {
  .text-review{
    margin-top: 30px;
    font-weight: bold;
    padding-right: 10%;
    font-size: 1rem;
  }
  .review img {
    height: 400px;
    border-radius: 10px;
  }
  
  .review {
    padding-left: 0;
  }
  
  .hero8 {
    padding-left: 10%;
    padding-top: 10px;
    padding-bottom: 250px;
  }
}