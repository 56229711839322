.header {
  width: 100%;
  background-color: #e9e9f0;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  border-radius: 20px;
  height: 200px;
  box-shadow: 0 4px 8px rgba(245, 241, 241, 0.2);
}

.blinking-text {
  padding-top: 20px;
  font-weight: bold;
  color: white;
  animation: blink-animation 2s infinite;
}

.blinking-text p {
  font-size: 30px;
  background: linear-gradient(45deg, #ff6f61, #de455d);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.blinking-text h1 {
  color: white;
  background: linear-gradient(45deg, #42a5f5, #478ed1);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 50px;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6);
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* CSS cho thông báo */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #3b5998;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  gap: 10px;
  animation: fade-in-out 1s;
  width: 800px; /* Mặc định cho màn hình lớn */
}

.notification-avatar {
  width: 50px; /* Kích thước ảnh mặc định */
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
}

.notification p {
  color: #f0f0f0;
  font-size: 50px; /* Kích thước văn bản mặc định */
  font-family: 'Arial', sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.large-text{
  font-size: 60px;
  color:#42a5f5;
}
@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

/* Điều chỉnh cho máy tính bảng (màn hình từ 600px đến 1024px) */
@media (max-width: 1024px) and (min-width: 600px) {
  .notification {
    width: 250px; /* Giảm chiều rộng */
    padding: 8px 16px;
  }

  .notification-avatar {
    width: 40px; /* Giảm kích thước avatar */
    height: 40px;
  }

  .notification p {
    font-size: 20px; /* Giảm kích thước văn bản */
  }

  .blinking-text p {
    padding-top: 40px;
    font-size: 30px;
  }

  .blinking-text h1 {
    color: white;
    background: linear-gradient(45deg, #42a5f5, #478ed1);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 40px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  .large-text{
    font-weight: bold;
    font-size: 40px;
    color:#42a5f5;
  }
}

/* Điều chỉnh cho điện thoại di động (màn hình nhỏ hơn 600px) */
@media (max-width: 600px) {
  .header {
    height: 150px;
  }
  .notification {
    width: 300px; /* Giảm chiều rộng hơn nữa */
    padding: 6px 12px;
  }

  .notification-avatar {
    width: 35px; /* Giảm kích thước avatar hơn nữa */
    height: 35px;
  }

  .notification p {
    font-size: 15px; /* Giảm kích thước văn bản nhỏ hơn */
    color: #f0f0f0;
  }

  .blinking-text p {
    font-size: 20px;
    background: linear-gradient(45deg, #ff6f61, #de455d);
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .blinking-text h1 {
    color: white;
    background: linear-gradient(45deg, #42a5f5, #478ed1);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 25px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  .large-text{
    font-size: 30px;
    color:#42a5f5;
  }
}
