/* File: Content5.css */

/* Toàn bộ container */
.content6-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  padding: 0;
  margin: 0;
  position: relative;
}

/* Box chứa cột nội dung */
.content6-box {
  display: flex;
  justify-content: flex-end; /* Đưa các phần tử về bên phải (mặc định) */
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Cột chứa văn bản */
.content6-text {
  font-weight: bold;
  color: white;
  text-align: left;
  max-width: 500px;
  display: flex;
  /* Đặt phần tử này là flex container */
  flex-direction: column;
  /* Xếp các phần tử con theo chiều dọc */
  justify-content: center;
  /* Căn giữa nội dung theo chiều dọc */
  align-items: center;
  /* Căn giữa các phần tử con theo chiều ngang */
}

/* Tiêu đề h1 */
.content6-text h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Đoạn văn bản */
.content6-text p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

/* Nút Join Now */
.btn-join6 {
  border-radius: 20px;
  background-color: green; /* Màu vàng */
  color: white;
  padding: 12px 25px;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Đảm bảo có khoảng cách giữa button và văn bản */
  text-align: center; /* Căn giữa chữ trong button */
}

/* Hover effect cho button */
.btn-join6:hover {
  background-color: #f8a146;
}

/* Media queries để căn giữa button khi màn hình nhỏ */
@media (max-width: 480px) {

  /* Căn giữa .content6-box */
  .content6-box {
    justify-content: center; /* Căn giữa các phần tử trong content6-box */
    align-items: center;     /* Căn giữa theo chiều dọc */
    text-align: center;      /* Căn giữa nội dung trong content6-box */
  }

  /* Căn giữa button */
  .btn-join6 {
    margin-top: 20px;   /* Khoảng cách phía trên */
    width: 100%;        /* Đảm bảo button chiếm toàn bộ chiều rộng */
    max-width: 300px;   /* Đặt chiều rộng tối đa cho button */
  }
}
