.hero {
    text-align: center;
    padding: 10px 0;
}

.hero img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Định dạng cho Slider */
.slider {
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
}

/* Responsive */
@media (max-width: 768px) {
    .hero {
        padding: 10px 0;
    }
}