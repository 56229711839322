/* File: Footer.css */

/* Toàn bộ footer */
.footer-wrapper {
    position: fixed; /* Giữ footer ở vị trí cố định */
    bottom: 0; /* Đặt footer ở dưới cùng của trang */
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffff; /* Màu nền của footer */
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3); /* Thêm bóng đổ nhẹ cho footer */
    z-index: 1000; /* Đảm bảo footer luôn nằm trên cùng */
  }
  
  /* Nút nhấp nháy */
  .btn-blinking {
    font-size:1.5rem;
    padding: 10px 20px;
    color: white;
    background-color: green; /* Màu nền của nút */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    animation: blink 1s infinite; /* Áp dụng hiệu ứng nhấp nháy */
  }
  
  /* Hiệu ứng nhấp nháy cho nút */
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Thay đổi chữ khi hover */
  .btn-blinking:hover {
    background-color: #0056b3; /* Thay đổi màu nền khi hover */
  }
  
  .text-large{
    font-weight:600;
    color: #132b3e;
    font-size: 1.7rem;
  }