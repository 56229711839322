/* Reset mặc định */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Container chính */
  .container5 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 20px; /* Khoảng cách giữa các cột */
  }
  
  /* Cột chữ */
  .text-column5 {
    margin-left:10% ;
    font-size: 2rem;
    font-weight: bold;
    flex: 1; /* Chiếm 50% chiều rộng */
    max-width: 600px;
    text-align: center;
    order: 1; /* Chữ đứng trước mặc định */
  }
  
  /* Cột ảnh */
  .image-column5 {
    flex: 1; /* Chiếm 50% chiều rộng */
    text-align: center;
    order: 2; /* Ảnh đứng sau mặc định */
  }
  
  .image5 {
    width: 80%;
    border-radius: 10px; /* Bo góc ảnh */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Hiệu ứng đổ bóng */
  }
  
  /* Làm nổi bật chữ */
  .highlight5 {
    background-color: #e0f7fa; /* Nền màu xanh nhạt */
    color: #00796b; /* Màu chữ xanh đậm */
    padding: 10px; /* Khoảng cách bên trong */
    border-radius: 5px; /* Bo góc nền */
    font-family: Arial, sans-serif; /* Phông chữ */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Hiệu ứng đổ bóng nhẹ */
    transition: transform 0.3s ease; /* Hiệu ứng chuyển động khi hover */
  }
  
  /* Hiệu ứng khi hover */
  .highlight5:hover {
    transform: scale(1.05); /* Phóng to nhẹ khi rê chuột */
    background-color: #b2ebf2; /* Thay đổi màu nền khi hover */
  }
  
  /* Nút mặc định */
.btn-join5 {
    margin-top: 30px;
    background-color: green; /* Màu nền xanh đậm */
    color: #ffffff; /* Màu chữ trắng */
    border: none; /* Xóa viền */
    padding: 10px 20px; /* Khoảng cách bên trong nút */
    font-size: 40px; /* Kích thước chữ */
    font-weight: bold; /* Chữ đậm */
    border-radius: 20px; /* Bo góc nút */
    cursor: pointer; /* Hiệu ứng con trỏ chuột */
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease; /* Hiệu ứng chuyển màu, phóng to và đổ bóng */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Đổ bóng nhẹ */
    text-transform: uppercase; /* Chuyển chữ thành chữ hoa */
    letter-spacing: 1px; /* Tăng khoảng cách giữa các ký tự */
    text-align: center; /* Căn giữa chữ bên trong nút */
    display: inline-block; /* Đảm bảo nút giữ nguyên kích thước */
    user-select: none; /* Ngăn người dùng chọn văn bản trên nút */
  }
  
  /* Hiệu ứng hover cho nút mặc định */
  .btn-join5:hover {
    background-color: #004d40; /* Đổi màu nền khi hover */
    transform: scale(1.05); /* Phóng to nhẹ nút */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Tăng độ đổ bóng */
  }
  
  /* Hiệu ứng khi nhấn nút */
  .btn-join5:active {
    background-color: #00332e; /* Màu nền tối hơn khi nhấn */
    transform: scale(0.98); /* Thu nhỏ nhẹ nút khi nhấn */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Giảm độ đổ bóng */
  }
  
  /* Định dạng cho nút bị vô hiệu hóa */
  .btn-join5:disabled {
    background-color: #bdbdbd; /* Màu nền xám nhạt */
    color: #ffffff; /* Màu chữ trắng */
    cursor: not-allowed; /* Con trỏ chuột hiển thị trạng thái không thể click */
    box-shadow: none; /* Loại bỏ đổ bóng */
    transform: none; /* Không có hiệu ứng khi hover */
  }
  
  /* Định dạng khi nút được focus (tiếp nhận bàn phím) */
  .btn-join5:focus {
    outline: 3px solid #80cbc4; /* Đường viền màu xanh nhạt */
    outline-offset: 2px; /* Khoảng cách giữa viền và nút */
  }
  
  /* Responsive Design: Khi màn hình nhỏ hơn 768px */
  @media (max-width: 768px) {
    .container5 {
      flex-direction: column; /* Sắp xếp theo cột */
    }
  
    .text-column5 {
      margin-left: 0;
      font-size: 1.3rem;
      padding: 0 10pt;
      order: 2; /* Đưa chữ xuống dưới */
      text-align: center;
    }
    
    .image-column5 {
      order: 1; /* Đưa ảnh lên trên */
    }
  }
  