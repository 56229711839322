/* Wrapper chính để đặt background hình ảnh */
.content3-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh; /* Chiếm toàn bộ chiều cao màn hình */
    background-size: cover; /* Đảm bảo background phủ kín */
    background-position: center; /* Đặt background ở giữa */
    background-repeat: no-repeat; /* Ngăn không cho background lặp lại */
    position: relative; /* Cần thiết để căn chỉnh hình ảnh ở giữa */
  }
  
  /* Box chứa hình ảnh giữa */
  .content3-box {
    position: absolute; /* Đặt hình ảnh vào giữa */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Đặt hình ảnh giữa */
  .center-image {
    max-width: 600px; /* Chiều rộng của hình ảnh giữa */
    height: auto; /* Giữ tỷ lệ hình ảnh */
    object-fit: contain; /* Đảm bảo hình ảnh không bị méo */
  }
  